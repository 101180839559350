import FirebaseRemoteConfigConnector from './firebase-remote-config-connector';

(async () => {
    const sgslVariableName = 'sgsl_test_group';
    const firebaseSupported = 'firebase_supported';
    const firebaseException = 'firebase_exception';

    window.firebaseConfig = window.firebaseConfig || {};

    if (!$.cookie(sgslVariableName)) {
        let sgslGroupValue = '';

        try {
            const connector = new FirebaseRemoteConfigConnector(window.firebaseConfig);
            var isSupported = await connector.isRemoteConfigSupportedByBrowser();
            console.log('Firebase is supported by browser:', isSupported);

            if (isSupported) {
                //sessionStorage.setItem(firebaseSupported, true);
                $.cookie(firebaseSupported, true, { expires: 1 });
                sgslGroupValue = await connector.connectAndFetch(sgslVariableName);
                console.log(sgslVariableName + ' was loaded with value: ' + sgslGroupValue);
            } else {
                //sessionStorage.setItem(firebaseSupported, false);
                $.cookie(firebaseSupported, false, { expires: 1 });
            }
        } catch (ex) {
            console.log('firebase exception happened', ex);
            $.cookie(firebaseException, ex, { expires: 1 });
        }

        //fallback
        if (!sgslGroupValue) {
            sgslGroupValue = 'CONTROL';
        }

        $.cookie(sgslVariableName, sgslGroupValue, { expires: 1 });
    }

    $(function () {
        let trackingData = {};

        trackingData[sgslVariableName.toUpperCase()] = $.cookie(sgslVariableName);
        trackingData[firebaseSupported.toUpperCase()] = $.cookie(firebaseSupported);
        if ($.cookie(firebaseException)) {
            trackingData[firebaseException.toUpperCase()] = $.cookie(firebaseException);
        }
        $(document).trigger('ga.tracking:assign-remote-config', [trackingData])
    });
})();