import "regenerator-runtime/runtime";

import {
    initializeApp
} from "./firebase/app";

import {
    getRemoteConfig,
    getValue,
    fetchAndActivate,
    isSupported as isRemoteConfigSupported
} from "./firebase/remote-config";

export default class FirebaseRemoteConfigConnector {
    constructor(config) {
        this.firebaseConfig = config || {};
    }

    async isRemoteConfigSupportedByBrowser() {
        return await isRemoteConfigSupported();
    }

    async connectAndFetch(variableName) {
        const app = initializeApp(this.firebaseConfig);
        const remoteConfig = getRemoteConfig(app);
        await fetchAndActivate(remoteConfig);

        var result = getValue(remoteConfig, variableName).asString();
        return result;
    }
}